import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import Home from "./Pages/Home";
import Blogs from "./Pages/Blogs";

import Header from "./components/Header"; // Import your Header component
import Footer from "./components/Footer";
import Blog01 from "./Pages/articles/article01";

// Create a layout component that includes the Header
const Layout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/blog01",
        element: <Blog01 />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
