import React, { useEffect } from "react";
import BlogCard from "../components/blog-card";
import ScrollMeraLogo from "../components/scrollMeraLogo";
import { Link } from "react-router-dom"; // Corrected import

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* Main wrapper div */}
      <div className="flex flex-col justify-start app-container py-4  px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto">
        {/* Hero section */}
        <main className="hero-container flex flex-col gap-4 justify-center items-center rounded-3xl py-8 px-2 bg-neutral-900 mt-16"> {/* Increased margin-top */}
          {/* Link to another page with a button introducing Mera */}
          <Link to="./blog01" className="px-2">
            <button className="rounded-full bg-gradient-to-r from-pink-400 to-sky-400 p-0.5">
              <div className="button-grad flex gap-3 text-neutral-800 px-4 py-2 bg-neutral-900 rounded-full items-center hover:bg-gradient-to-r from-pink-400 to-sky-400 ease-linear transition-all">
                <span className="text-white">Announcements</span>
              </div>
            </button>
          </Link>

          {/* Main headline */}
          <h1 className="font-bold text-3xl lg:text-5xl text-white text-center my-3 ">
            Mera Insights & Updates
          </h1>
        </main>

        {/* blog link cards components */}
        <section className="my-8 flex flex-col gap-6 w-full lg:w-3/4">
          {/* blog cards are inside components directory, it takes props such as title date and links */}

          <BlogCard
            date={"Sep 13th, 2024"}
            title={"First Glance, Meet Mera"}
            link={"/blog01"}
          />
          <hr />
          {/* <BlogCard
            date={"Sep 02 2024"}
            title={
              "Lorem ipsum dolor sit amet consectetur. Hendrerit a sapien vel sem. Nunc ullamcorper dictumst vel enim donec."
            }
            link={"/blog01"}
          />
          <hr />
          <BlogCard
            date={"Sep 02 2024"}
            title={
              "Lorem ipsum dolor sit amet consectetur. Hendrerit a sapien vel sem. Nunc ullamcorper dictumst vel enim donec."
            }
            link={"/blog01"}
          /> */}
          
        </section>
      </div>

      {/* Logo icon Scroll bar component */}
      <ScrollMeraLogo />
    </div>
  );
};

export default Blogs;
