import React, { useState } from "react";
import logo from "../assets/images//Mera Labs Logo/Logo_black.png";
import { Link } from "react-router-dom";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar bg-none fixed top-0 left-0 w-full z-40 bg-white"> {/* Added bg-white */}
      <header className="h-fit flex w-100 justify-between items-center px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto py-4 lg:py-4"> {/* Increased padding-top */}
        <Link to="/">
          <img src={logo} alt="Logo" className="logo h-6 w-auto" />
        </Link>

        <button className="md:hidden text-slate-900" onClick={toggleMenu}>
          <i className={`bi ${menuOpen ? "bi-x" : "bi-list"} text-2xl`}></i>
        </button>
        <div
          className={`lg:flex ${
            menuOpen ? "flex" : "hidden"
          } flex-col md:flex-row gap-6 md:gap-8 items-center absolute md:static top-16 left-0 shadow-lg md:shadow-none w-full md:w-auto bg-white md:bg-transparent md:top-auto md:left-auto px-4 md:px-0 py-6 md:py-0`}
        >
          <div className="flex flex-col items-center md:flex-row gap-6 text-slate-900">
            <Link
              to="/blogs"
              className="text-neutral-600 hover:text-neutral-900"
            >
              Blogs
            </Link>
            <button
              className="text-neutral-600 hover:text-neutral-900"
              onClick={toggleModal}
            >
              Founder Message
            </button>
          </div>
          {/* Video Modal Section for Founder Message */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-black/5 p-4 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
                <button
                  onClick={toggleModal}
                  className="flex w-full justify-end mb-2"
                >
                  <i className="bi bi-x-lg text-xl text-gray-300"></i>
                </button>
                <div className="relative pb-9/16">
                  <iframe
                    className="w-full h-96 rounded-2xl"
                    src="https://www.youtube.com/embed/JzSy9NZtEek"
                    title="Founder Message"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          )}

          <a href="https://cal.com/peacemakerbak/30min" target="_blank">
            <button className="bg-siteGray hover:bg-gradient-to-br  from-pink-300    to-blue-400 px-3 py-2 rounded-xl nav-button text-slate-900">
              <span className="me-2">Get In Touch</span>
            </button>
          </a>
        </div>
      </header>
    </div>
  );
}

export default Header;
