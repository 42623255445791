import React from "react";
import { Link } from "react-router-dom";

// BlogCard component that displays a blog post's date, title, and a link to the full post.
function BlogCard({ date, title, link }) {
  return (
    <div>
      {/* Blog card section with date, title, and 'Read More' button */}
      <section className="blog-card flex flex-col gap-3 py-4 items-start justify-center">
        {/* Date of the blog post */}
        <p className="text-md text-neutral-400">{date}</p>
        {/* Title of the blog post */}
        <h3 className="text-2xl text-neutral-900">{title}</h3>
        {/* 'Read More' button linking to the full post */}
        <Link to={link}>
          <button className="flex gap-2 text-blue-500 hover:bg-neutral-100 hover:px-4 py-2 transition-all ease-linear">
            <span>Read More</span>
            <i className="bi bi-arrow-right"></i>
          </button>
        </Link>
      </section>
    </div>
  );
}

export default BlogCard;
