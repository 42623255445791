import React, { useState } from "react";
import { Link } from "react-router-dom";
import cardImg from "../assets/images/card-img.png";
import ScrollMeraLogo from "../components/scrollMeraLogo";

function Home() {
  // State to manage the user's name input
  const [name, setName] = useState("");

  // State to control video visibility in the modal
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // Function to handle name submission, updates name state
  const handleNameSubmit = (name) => {
    setName(name);
  };

  // Function to toggle the visibility of the video in the modal
  const handleToggleVideo = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  return (
    <div>
      {/* Main wrapper div */}
      <div className="wrapper ">
        {/* Main content container */}
        <div className="flex flex-col app-container py-4 px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto pt-20"> {/* Added padding-top */}
          {/* Hero section */}
          <main className="hero-container flex flex-col gap-4 justify-center items-center rounded-3xl py-8 px-2">
            {/* Link to another page with a button Meet Mera */}
            <Link to="./blog01" className="px-2">
              <button className="rounded-full bg-gradient-to-r from-pink-400 to-sky-400 p-0.5 ">
                <div className="button-grad flex gap-3 text-white p-1 pe-3 bg-neutral-800 rounded-full items-center hover:bg-gradient-to-r from-pink-400 to-sky-400 hover:text-neutral-800 ease-linear transition-all">
                  <span className="button-inner-fill py-2 px-4 bg-gradient-to-r from-pink-400 to-sky-400 text-neutral-800 rounded-full text-xs lg:text-md">
                    New
                  </span>
                  <p className="text-xs lg:text-lg">
                    Meet Mera! Your Voice-First Browser Assistant
                  </p>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </button>
            </Link>

            {/* Main headline */}
            <h1 className="font-bold text-3xl lg:text-5xl text-white text-center my-3 ">
              Talk, Don't Type
            </h1>

            {/* Video section */}
            <div className="video-section w-full md:w-4/5 lg:w-3/5 xl:w-1/2 aspect-video px-2 mb-2">
              <div className="relative md:p-2 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl w-full h-full">
                <div className="video-container relative bg-white p-0 rounded-2xl h-full">
                  <iframe
                    className="w-full h-full rounded-2xl"
                    src="https://www.youtube.com/embed/91xb8nj978Y"
                    title="some video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>

            {/* Contact button */}
            <a href="https://cal.com/peacemakerbak/30min" target="_blank">
              <button className="rounded-xl px-4 py-2 bg-neutral-800 text-white border border-white hover:bg-white hover:text-neutral-800 transition-all ease-linear">
                <span className="">Get In Touch</span>
              </button>
            </a>
          </main>

          {/* Card section */}
          <section className="cards w-full flex flex-col md:flex-row gap-6 my-6 md:mt-10 lg:mt-16">
            {/* First card */}
            <div className="card-small p-6 lg:p-8 bg-gray-100 flex flex-col gap-4 rounded-2xl transition-transform transform hover:scale-105 hover:shadow-lg">
              <img src={cardImg} alt="card image" className="rounded-2xl" />
              <h1 className="text-3xl text-neutral-800 w-full lg:w-2/3">
                Master Your Inbox Without Lifting a Finger
              </h1>
              <p className="text-lg text-neutral-500">
                From reading and organizing to drafting and sending emails, Mera
                handles it all with simple voice commands, freeing you from the
                daily email grind.
              </p>
            </div>

            {/* Second card */}
            <div className="card-small p-6 lg:p-8 bg-gray-100 flex flex-col gap-4 rounded-2xl transition-transform transform hover:scale-105 hover:shadow-lg">
              <img src={cardImg} alt="card image" className="rounded-2xl" />
              <h1 className="text-3xl text-neutral-800 w-full lg:w-2/3">
                Master Your Inbox Without Lifting a Finger
              </h1>
              <p className="text-lg text-neutral-500">
                From reading and organizing to drafting and sending emails, Mera
                handles it all with simple voice commands, freeing you from the
                daily email grind.
              </p>
            </div>
          </section>

          {/* Announcement button */}
          <span className="flex w-full justify-center">
            <a href="https://mera-labs.canny.io/feature-requests" target="_blank" rel="noopener noreferrer"> {/* feature request page */}
              <button className="rounded-full bg-gradient-to-r from-pink-400 to-sky-400 p-0.5 w-fit">
                <div className="button-grad2 flex gap-3 text-neutral-800 px-4 py-2 bg-white rounded-full items-center hover:bg-gradient-to-r from-pink-400 to-sky-400 hover:text-neutral-800 ease-linear transition-all">
                  <i className="bi bi-bell bg-gradient-to-r from-pink-400 to-sky-400 inline-block text-transparent bg-clip-text"></i>
                  <span className="bg-gradient-to-r from-pink-400 to-sky-400 inline-block text-transparent bg-clip-text">
                    Request Features 
                  </span>
                </div>
              </button>
            </a>
          </span>

          {/* Info section with contact btn */}
          <div className="h-80 bg-neutral-700 rounded-3xl my-4 flex flex-col items-center justify-center gap-8 p-2">
            <h1 className="text-3xl w-full md:w-2/3 text-center bg-gradient-to-r from-pink-200 to-sky-200 inline-block text-transparent bg-clip-text">
              Mera handles the tasks that take up your time, so you can focus on
              what truly matters
            </h1>
            <a href="https://cal.com/peacemakerbak/30min" target="_blank">
              <button className="rounded-xl px-4 py-2 bg-neutral-600 text-white border border-white hover:bg-white hover:text-neutral-800 transition-all ease-linear">
                <span className="">Get In Touch</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Logo icon Scroll bar component */}
      <ScrollMeraLogo />
    </div>
  );
}

export default Home;
