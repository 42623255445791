import React from "react";
import meraLogo from "../assets/images/Mera Labs Logo/Logo_black.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-neutral-100">
      <div className=" py-8 lg:py-10  px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-8 lg:gap-0 text-slate-900">
          {/* Footer Left Column */}
          {/* Footer Right Column */}
          <div className="flex flex-col gap-8 lg:items-end items-center">
            <div className="flex flex-col md:flex-row gap-5 text-center lg:text-right">
              <img src={meraLogo} alt="mera logo" className="h-5 w-auto" />
              <a
                href="mailto:peacemaker@meralabs.io"
                className=" hover:underline text-neutral-600 hover:text-neutral-900"
              >
                Contact
              </a>
              <Link
                to={"/blogs"}
                className="text-neutral-600 hover:text-neutral-900 hover:underline"
              >
                Learn More
              </Link>
            </div>
          </div>
          <p className=" text-center text-neutral-500 lg:text-left">
            Copyright © 2024 Mera Labs Technologies Inc. All Rights Reserved.
          </p>
        </div>
        {/* <hr className="mt-3" /> */}
      </div>
    </div>
  );
}

export default Footer;
