import React, { useEffect, useState } from "react";
import image from "../../assets/images/card-img.png";
import ScrollMeraLogo from "../../components/scrollMeraLogo";
import { Link } from "react-router-dom";

const Blog01 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-start app-container py-4 px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto mt-16"> {/* Adjusted mt-16 for a balanced top margin */}
        {/* Breadcrumb section for the blog page */}
        <div className="blog-content  w-full lg:w-3/5">
          <span className="breadcrumb flex gap-2 text-neutral-500 my-4 lg:my-8 lg:mt-12">
            <Link
              to="/blogs"
              className="hover:text-neutral-900 transition-all ease-linear"
            >
              Blog
            </Link>
            <p>/</p>
            <span className="text-neutral-900">
              First Glance, Meet Mera
            </span>
          </span>
          {/* Blog Title */}
          <h1 className="text-2xl lg:text-6xl text-neutral-900 mb-8">
            First Glance, Meet Mera
          </h1>
          <p className="postedDate text-neutral-500 mb-6 lg:mb-12">
            Sept 13th, 2024
          </p>

          {/* blog content */}

          <section className="blogVideo h-96 bg-neutral-300 w-full rounded-3xl mb-6">
            <div className="video-container relative bg-white p-0 rounded-2xl h-full">
              <iframe
                className="w-full h-full rounded-2xl"
                src="https://www.youtube.com/embed/91xb8nj978Y"
                title="some video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </section>
          <p className="text-neutral-700 text-balance leading-relaxed mb-10">
  We’re thrilled to introduce our MVP version of <strong>Mera</strong>, a voice-activated AI assistant designed to streamline your web browsing. With Mera, repetitive tasks like responding to emails or scheduling meetings can now be done effortlessly through simple voice commands.
  <br />
  <br />
  Our goal is to transform the way you work and interact with your browser by bringing conversational interaction to the forefront. 
  We’re building Mera as a smarter, more intuitive tool that gives you control of your digital environment with ease and productivity in mind.
</p>

<h3 className="text-xl lg:text-2xl text-neutral-800 mb-6">
  Our Vision
</h3>

<p className="text-neutral-700 leading-relaxed mb-8">
  Our vision is to lead the shift towards <strong>voice-first computing</strong>, 
  eliminating the need for keyboards and mice. We believe the future of computing will be hands-free, 
  allowing you to control everything with just your voice. As we push the boundaries of what’s possible, 
  Mera Labs wants to be at the forefront of building this future.
  <br />
  <br />
  For early access to Mera, questions, or feature requests, feel free to reach out at <a href="mailto:peacemaker@meralabs.io" className="underline">peacemaker@meralabs.io</a>.
</p>

        </div>
      </div>

      {/* Logo icon Scroll bar component */}

      <ScrollMeraLogo />
    </div>
  );
};

export default Blog01;
