import React, { useEffect, useState } from "react";
import meraIcon from "../assets/images/M-Labs Solo Logos/Logo_black.svg";

const ScrollMeraLogo = () => {
  const [scrollPercentage, setScrollPercentage] = useState(20); // Start lower

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Current vertical scroll position
      const windowHeight = window.innerHeight; // Height of the visible window
      const docHeight = document.documentElement.scrollHeight; // Total height of the document

      // Calculate the scroll percentage (how far the user has scrolled relative to the page height)
      const totalScroll = (scrollTop / (docHeight - windowHeight)) * 100;
      setScrollPercentage(totalScroll + 9); // Update the state with the scroll percentage
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      {" "}
      {/* Scroll image */}
      <img
        src={meraIcon}
        alt="Small Icon"
        className="fixed right-4 z-90 hidden md:block z-50" // Fixed to stay on screen
        style={{
          top: `${scrollPercentage}%`, // make it Move down as you scroll
          transition: "top 0.1s ease-out", // for Smooth movement
          width: "50px", // Enlarge icon
          height: "50px", // Enlarge icon
        }}
      />
    </div>
  );
};

export default ScrollMeraLogo;
